var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { defineComponent, inject, reactive, ref, computed, onBeforeMount } from 'vue';
import { IonPage, IonContent, IonCheckbox } from '@ionic/vue';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';
import TermsAndConditions from '@/components/termsAndConditions.vue';
import i18n from '@/i18n';
import { Preferences } from '@capacitor/preferences';
export default defineComponent({
    components: {
        IonPage: IonPage,
        IonContent: IonContent,
        IonCheckbox: IonCheckbox,
        TermsAndConditions: TermsAndConditions
    },
    setup: function () {
        var _this = this;
        var authStore = inject('authState');
        var configurationStore = inject('configurationStore');
        var uniqueValidationFormData = reactive(configurationStore.state.uniqueValidationFormData);
        var validPrefixList = computed(function () { return authStore.state.validPrefixList; });
        var router = useRouter();
        var tos = ref(false);
        var showTerms = ref(false);
        var emailSuccess = ref(false);
        var mobileSuccess = ref(false);
        var emailCodeSuccess = ref(false);
        var mobileCodeSuccess = ref(false);
        var termsConditionsSuccess = ref(false);
        var goTo = function (url) {
            router.push({ name: url });
        };
        onBeforeMount(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, authStore.obtainCountry()];
                    case 1:
                        _a.sent();
                        setDefaultValues();
                        return [2 /*return*/];
                }
            });
        }); });
        var validateEmail = function () { return __awaiter(_this, void 0, void 0, function () {
            var data, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        data = {
                            newemail: configurationStore.state.uniqueValidationFormData.emailData.email
                        };
                        emailCodeSuccess.value = false;
                        configurationStore.state.uniqueValidationFormData.emailData.validationCode = '';
                        _a = emailSuccess;
                        return [4 /*yield*/, configurationStore.validateUniqueEmail(data)];
                    case 1:
                        _a.value = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var validateMobile = function () { return __awaiter(_this, void 0, void 0, function () {
            var data, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        configurationStore.state.uniqueValidationFormData.mobileData.input.showError = false;
                        if (!(configurationStore.state.uniqueValidationFormData.mobileData.number.length != 9)) return [3 /*break*/, 1];
                        configurationStore.state.uniqueValidationFormData.mobileData.input.showError = true;
                        configurationStore.state.uniqueValidationFormData.mobileData.input.errorCode = 'tester_mobile_incorrect';
                        return [3 /*break*/, 3];
                    case 1:
                        data = {
                            prefix: configurationStore.state.uniqueValidationFormData.mobileData.prefix,
                            number: configurationStore.state.uniqueValidationFormData.mobileData.number
                        };
                        mobileCodeSuccess.value = false;
                        configurationStore.state.uniqueValidationFormData.mobileData.validationCode = '';
                        _a = mobileSuccess;
                        return [4 /*yield*/, configurationStore.validateUniqueMobile(data)];
                    case 2:
                        _a.value = _b.sent();
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        var verifyEmailCode = function () { return __awaiter(_this, void 0, void 0, function () {
            var data, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        data = {
                            validationCode: configurationStore.state.uniqueValidationFormData.emailData.validationCode
                        };
                        _a = emailCodeSuccess;
                        return [4 /*yield*/, configurationStore.verifyUniqueEmailCode(authStore.state.userData.identifier, data)];
                    case 1:
                        _a.value = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var verifyMobileCode = function () { return __awaiter(_this, void 0, void 0, function () {
            var data, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        data = {
                            validationCode: configurationStore.state.uniqueValidationFormData.mobileData.validationCode
                        };
                        _a = mobileCodeSuccess;
                        return [4 /*yield*/, configurationStore.verifyUniqueMobileCode(authStore.state.userData.identifier, data)];
                    case 1:
                        _a.value = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var acceptTermsConditions = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = termsConditionsSuccess;
                        return [4 /*yield*/, configurationStore.confirmTermsConditions(authStore.state.userData.identifier)];
                    case 1:
                        _a.value = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var uniqueFormSubmit = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        configurationStore.state.uniqueValidationFormData.emailData.showError = false;
                        configurationStore.state.uniqueValidationFormData.mobileData.showError = false;
                        return [4 /*yield*/, authStore.getUserDetails()];
                    case 1:
                        _a.sent();
                        Swal.fire({
                            icon: 'success',
                            title: i18n.global.t('tester_unique_validated'),
                            showConfirmButton: true,
                            confirmButtonText: i18n.global.t('Accept'),
                        }).then(function (result) {
                            if (result.isConfirmed || result.dismiss === Swal.DismissReason.esc || result.dismiss === Swal.DismissReason.backdrop) {
                                redirect();
                            }
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        var redirect = function () { return __awaiter(_this, void 0, void 0, function () {
            var needs2fa;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Preferences.get({ key: 'needs2FA' })];
                    case 1:
                        needs2fa = _a.sent();
                        if (needs2fa.value) {
                            goTo('validate2fa');
                        }
                        else {
                            goTo('tests.index');
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        var setErrorMessage = function (errorCode) {
            switch (errorCode) {
                case 'code_not_correct':
                    return i18n.global.t('tester_pin_code_error');
                case 'tester_mobile_incorrect':
                    return i18n.global.t('tester_mobile_incorrect');
                case 'registration_mobile_fraud':
                    return i18n.global.t('tester_duplicate_mobile_error');
                case 'default_error_message':
                default:
                    return i18n.global.t('tester_default_error');
            }
        };
        var handleShowTerms = function () {
            showTerms.value = false;
        };
        var setDefaultValues = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                uniqueValidationFormData.mobileData.prefix = authStore.state.userData.mobilePrefix;
                uniqueValidationFormData.mobileData.number = authStore.state.userData.mobileNum;
                uniqueValidationFormData.emailData.email = authStore.state.userData.email;
                return [2 /*return*/];
            });
        }); };
        var uniqueFormValid = computed(function () {
            return emailCodeSuccess.value && mobileCodeSuccess.value && tos.value && termsConditionsSuccess.value;
        });
        return {
            uniqueValidationFormData: uniqueValidationFormData,
            uniqueFormSubmit: uniqueFormSubmit,
            validateEmail: validateEmail,
            validateMobile: validateMobile,
            goTo: goTo,
            validPrefixList: validPrefixList,
            uniqueFormValid: uniqueFormValid,
            showTerms: showTerms,
            handleShowTerms: handleShowTerms,
            tos: tos,
            emailSuccess: emailSuccess,
            mobileSuccess: mobileSuccess,
            verifyEmailCode: verifyEmailCode,
            verifyMobileCode: verifyMobileCode,
            mobileCodeSuccess: mobileCodeSuccess,
            emailCodeSuccess: emailCodeSuccess,
            setErrorMessage: setErrorMessage,
            acceptTermsConditions: acceptTermsConditions,
            termsConditionsSuccess: termsConditionsSuccess,
            redirect: redirect
        };
    }
});
